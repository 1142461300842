export class Category {
  constructor(dto, parent) {
    this.id = dto.id;
    this.code = dto.code;
    this.name = dto.name;
    this.slug = dto.slug;
    this.assets = dto.assets;
    this.productsCount = dto.productsCount;
    this.compilation = dto.compilation;
    this.promotion = dto.promotion;
    this.parent = parent;
  }

  getName() {
    return getLocalizedName(this.name);
  }

  getImageUrl(type, pluggable = true) {
    const imageUrl = this.assets.find((image) => image.type === type);

    if (undefined !== imageUrl) {
      return getLocalizedName(imageUrl.url);
    }

    return pluggable ? "/img/photo-plug.svg" : undefined;
  }
}
