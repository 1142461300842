export const cleanStringsConcat = (strings) => {
  return strings
    .join(" ")
    .replace(/ +(?= )/g, "")
    .trim();
};

export const getFullName = (name) => {
  return cleanStringsConcat([
    name.lastName,
    name.firstName,
    name.middleName ? name.middleName : "",
  ]);
};
