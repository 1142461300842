<template>
  <div
    ref="refReviewModal"
    class="modal"
    tabindex="0"
    @keydown.esc="closeModal"
  >
    <div v-on-click-outside="closeModal" class="modal__wrapper review">
      <div class="review-modal">
        <div class="review-modal__header">
          <p class="review-modal__modal-name">
            {{ _T("@Write review") }}
          </p>
          <button class="review-modal__close" @click.stop.prevent="closeModal">
            <img src="~~/assets/icons/cross.svg" alt="close button" />
          </button>
        </div>
        <div class="review-modal__body">
          <form action="" class="review-modal__form">
            <div class="review-modal__rating">
              <NuxtRating
                :rating-size="44"
                :rating-step="1"
                :read-only="false"
                :rating-value="form.rating"
                active-color="#fbbd09"
                @rating-selected="onSelectRating"
              />
              <p
                v-if="v.rating.$invalid && v.rating.$dirty"
                class="review-modal__error-label"
              >
                {{ _T("@Entry field required") }}
              </p>
            </div>
            <div class="review-modal__inputs">
              <template v-if="null === user">
                <InputText
                  v-model="form.author.name"
                  :validate="v.author.name"
                  :maxlength="255"
                  :caption="_T('@Name')"
                  :autocomplete-type="'given-name'"
                  class="review-modal__input"
                />
                <InputMail
                  v-model="form.author.email"
                  :validate="v.author.email"
                  :caption="'E-mail'"
                  class="review-modal__input"
                />
              </template>
              <InputText
                v-model="form.pros"
                :validate="v.pros"
                :maxlength="255"
                :minlength="3"
                :caption="_T('@Pros')"
                :required="false"
                class="review-modal__input"
              />
              <InputText
                v-model="form.cons"
                :validate="v.cons"
                :maxlength="255"
                :minlength="3"
                :caption="_T('@Cons')"
                :required="false"
                class="review-modal__input"
              />
            </div>
            <InputTextarea
              v-model="form.content"
              :validate="v.content"
              :maxlength="2048"
              :minlength="3"
              :caption="_T('@Your comment')"
              class="review-modal__input"
            />
            <label class="review-modal__agree">
              <input v-model="form.isReplyTracked" type="checkbox" />
              <span class="review-modal__caption">
                {{ _T("@Product notify me of replies by email") }}
              </span>
            </label>
            <div class="review-modal__checkbox-wrp">
              <button
                class="review-modal__btn"
                :disabled="addReviewHandler.isHandled"
                @click.prevent="onAddReview"
              >
                {{ _T("@Post a review") }}
              </button>
              <label
                class="review-modal__agree"
                :class="{ error: v.isAgree.$invalid && v.isAgree.$dirty }"
              >
                <input v-model="form.isAgree" type="checkbox" />
                <span class="review-modal__caption">
                  <span
                    >{{ _T("@I agree with") }}
                    <MyLink
                      class="review-modal__caption-link"
                      name="reviews-policy"
                      target="_blank"
                    >
                      {{ _T("@Reviews policy") }}
                    </MyLink>
                  </span>
                </span>
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import InputTextarea from "~~/modules/shared/components/inputs/InputTextarea.vue";
import useVuelidate from "@vuelidate/core";
import InputText from "~~/modules/shared/components/inputs/InputText.vue";
import InputMail from "~/modules/shared/components/inputs/InputMail.vue";
import {
  reviewModal,
  successfulModal,
  useModalStore,
} from "~/modules/shared/components/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  minLengthValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import { modalFormStore } from "~/modules/shared/components/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { vOnClickOutside } from "@vueuse/components";
import { useAPI } from "~/uses/useMyFetch";
import { helpers, minValue, requiredIf } from "@vuelidate/validators";

const emits = defineEmits(["close"]);
const modalStore = useModalStore();
const formStore = modalFormStore();
const userStore = useUserStore();

const { currentUser: user } = storeToRefs(userStore);
const product = storeToRefs(modalStore).getExtra.value(reviewModal);

const refReviewModal = ref(null);

const onSelectRating = (rating) => {
  form.rating = rating;
};

onMounted(() => {
  document.documentElement.style.overflow = "hidden";
  refReviewModal.value.focus();
  form.productId = product.id;

  formStore.addForm({ v, requestOptions, id: "review" });
});

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Review sent successfully"),
  isWithTimeout: true,
};

const form = reactive({
  productId: "",
  author: {
    email: "",
    name: "",
  },
  pros: null,
  cons: null,
  content: "",
  isReplyTracked: false,
  rating: 0,
  isAgree: true,
});

const rules = computed(() => ({
  author: {
    name: {
      required: helpers.withMessage(
        _T("@Entry field required"),
        requiredIf(() => {
          return null === user;
        }),
      ),
      maxLength: maxLengthValidate("@Maximum field length", 255),
    },
    email: {
      required: helpers.withMessage(
        _T("@Entry field required"),
        requiredIf(() => {
          return null === user;
        }),
      ),
      email: emailValidate(),
      maxLength: maxLengthValidate("@Maximum field length", 254),
    },
  },
  pros: {
    minLength: minLengthValidate("@Minimum field length", 3),
    maxLength: maxLengthValidate("@Maximum field length", 512),
  },
  cons: {
    minLength: minLengthValidate("@Minimum field length", 3),
    maxLength: maxLengthValidate("@Maximum field length", 512),
  },
  content: {
    required: requiredValidate(),
    minLength: minLengthValidate("@Minimum field length", 3),
    maxLength: maxLengthValidate("@Maximum field length", 2048),
  },
  isReplyTracked: {},
  rating: {
    required: requiredValidate,
    minValue: minValue(1),
  },
  isAgree: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

const requestOptions = () => {
  return {
    productId: form.productId,
    author:
      null !== user.value
        ? null
        : {
            email: form.author.email,
            name: form.author.name,
          },
    pros: form.pros || null,
    cons: form.cons || null,
    content: form.content,
    isReplyTracked: form.isReplyTracked,
    rating: form.rating,
  };
};

const addReviewHandler = useSingletonHandler(() =>
  useAPI("/catalog/product/review/add", {
    method: "POST",
    body: {
      ...requestOptions(),
    },
  }).then(() => {
    closeModal();

    modalStore.toggleModal(successfulModal, successfulData);
  }),
);

const onAddReview = async () => {
  if (!formStore.isFormsReady()) {
    return;
  }

  return await addReviewHandler.handle();
};

const closeModal = () => {
  emits("close");
  document.documentElement.style.overflow = "auto";
};

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.review-modal {
  background-color: white;
  border-radius: 8px;

  &__header {
    @include flex-container(row, space-between, center);

    border-bottom: 1px solid #d1d1d1;

    padding: 16px;
    gap: 16px;
  }

  &__modal-name {
    @include font(20, 28, 600);
    letter-spacing: 0.02em;
    color: #2b2b2b;
  }

  &__close {
    @include fixedHW(32px, 32px);

    @include flex-container(row, center, center);

    & img {
      @include fixedHW(16px, 16px);
    }
  }

  &__body {
    @include flex-container(column, space-between);

    padding: 24px;
    gap: 24px;

    @include mobile {
      padding: 16px;
    }
  }

  &__form {
    width: 100%;
    @include flex-container(column, space-between);

    gap: 24px;
  }

  &__rating {
    margin-bottom: 16px;
  }

  &__error-label {
    @include font(12, 16);

    color: #fb2424;

    padding-inline: 8px;
  }

  &__inputs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

    gap: 24px;

    @include mobile {
      @include flex-container(column);
      gap: 16px;
    }
  }

  &__input {
    max-width: none;
  }

  &__checkbox-wrp {
    width: min(100%, 330px);
    align-self: center;

    @include flex-container(column, center, center);
    gap: 24px;

    @include mobile {
      width: 100%;
    }
  }

  &__agree {
    position: relative;

    align-self: flex-start;

    @include mobile {
      align-self: center;
    }

    & input {
      height: 0;
      width: 0;

      position: absolute;
      left: 0;
      top: 50%;
      z-index: -1;

      opacity: 0;
      visibility: hidden;
    }

    & > span {
      @include flex-container(row, space-between, center);
      gap: 16px;

      user-select: none;
    }

    & > span::before {
      content: "";
      display: inline-block;

      width: 24px;
      height: 24px;

      flex: 0 0 24px;

      border: 1px solid #cdcfd0;
      border-radius: 4px;

      background-repeat: no-repeat;
      background-position: center;

      transition: 0.2s ease;
    }

    &.error > span::before {
      border-color: #f73b2f;
    }

    & input:not(:checked) + span:hover::before {
      border-color: var(--color-primary-base);
    }

    & input:checked + span::before {
      border-color: var(--color-primary-base);
      background-color: var(--color-primary-base);
      background-image: url("~~/assets/icons/check-icon.svg");
    }
  }

  &__caption {
    @include font(12, 16);
  }

  &__caption-link {
    color: var(--color-primary-base);
  }

  &__textarea {
    resize: none;
    @include font(16, 22, 400);

    border-radius: 8px;
    border: 1px solid grey;

    padding: 17px 14px;

    &:invalid {
      border-color: red;
    }
  }

  &__btn {
    @include font(18, 24, 400);
    color: white;

    @include flex-container(row, center, center);

    background-color: var(--color-primary-base);
    border-radius: 30px;

    padding: 8px 32px;

    transition: background-color 0.2s;

    @include mobile {
      max-width: 400px;
      width: 100%;

      @include font(16, 22, 700);
    }

    &:hover {
      background-color: var(--color-primary-dark);
    }

    &:disabled {
      cursor: auto;

      border-color: #8a8a8a;
      background-color: #8a8a8a;
    }
  }
}

.modal {
  &__wrapper.review {
    max-width: 1015px;
  }
}
</style>
