<template>
  <div 
    class="offer-card"
    :style="[
      { '--title-color': titleData.titleColor },
      { '--description-color': descriptionData.descriptionColor },
      { '--link-color': linkData.titleColor },
      { '--link-border-color': linkData.borderColor },
      { '--link-bg': linkData.bgColor },
      { '--bg': bgCard },
    ]"
  >
    <div class="offer-card__wrp">
      <div class="offer-card__content">
        <div class="offer-card__txt-wrp">
          <h4 v-if="titleData.title" class="offer-card__title">{{ titleData.title }}</h4>
          <p v-if="descriptionData.description" class="offer-card__description">{{ descriptionData.description }}</p>
        </div>
        <a 
          class="offer-card__link" 
          :href="linkData.url"
        >
          {{ linkData.title }}
        </a>
      </div>

      <img class="offer-card__img" :src="useNormalizeCtfUrl(image.url)" :alt="image.alt">
    </div>
  </div>
</template>

<script setup>
import { useNormalizeCtfUrl } from "~/uses/useNormalizeCtfUrl";

const props = defineProps({
  itemData: { type: Object, required: true },
  lang: { type: Object, required: true },
});

const titleData = computed(() => {
  return {
    title: props.itemData.fields?.title[props.lang.name] || "",
    titleColor: props.itemData.fields?.colorTitle?.ru || "white",
  };
});

const descriptionData = computed(() => {
  return {
    description: props.itemData.fields?.description[props.lang.name] || "",
    descriptionColor: props.itemData.fields?.colorText?.ru || "white",
  };
});

const linkData = computed(() => {
  return {
    title: props.itemData.fields?.textBtn[props.lang.name] || "",
    titleColor: props.itemData.fields?.colorTextBtn?.ru || "white",
    borderColor: props.itemData.fields?.colorBorderBtn?.ru || "white", 
    bgColor: props.itemData.fields?.backroundColorBtn?.ru || "white",
    url: props.itemData.fields?.urlBtn?.ru || "#",
  };
});

const bgCard = computed(() => `url('${useNormalizeCtfUrl(props.itemData.fields?.backgroundImg.ru.fields.file.ru.url)}')`);

const image = computed(() => {
  return {
    url: props.itemData.fields?.img.ru.fields.file.ru.url || "",
    alt: props.itemData.fields?.img.ru.fields.title[props.lang.name] || "image",
  };
});

</script>

<style lang="scss" scoped>
  .offer-card {
    --title-color: white;
    --description-color: white;
    --link-color: white;
    --link-border-color: transparent;
    --link-bg: transparent;
    --bg: none;

    height: 100%;

    position: relative;
    z-index: 10;

    background-color: var(--color-ink-base);
    background-image: var(--bg);
    background-repeat: no-repeat;
    background-size: cover;

    border-radius: 16px;

    &__wrp {
      height: 100%;

      padding: 16px 16px 24px;

      @include bigMobile {
        padding: 8px;
      }
    }

    &__content {
      height: 100%;

      position: relative;
      z-index: 5;

      @include flex-container(column, space-between, flex-start);

      gap: 16px;
    }

    &__txt-wrp {
      @include flex-container(column);

      gap: 8px;
    }

    &__title {
      @include font(32, 40, 700);
      color: var(--title-color);

      @include bigMobile {
        @include font(20, 26, 700);
      }
    }

    &__description {
      @include font(12, 14);
      color: var(--description-color);
    }

    &__link {
      @include font(16, 20);
      color: var(--link-color);

      border-radius: 30px;
      border: 1px solid var(--link-border-color);

      background-color: var(--link-bg);

      box-shadow: 0px 0px 4px 0px #00000066;

      padding: 8px 24px;
    }

    &__img {
      width: 50%;
      
      position: absolute;
      top: 0;
      right: 0;
    }
  }
</style>
