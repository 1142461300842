import { useAsyncPrivateAPI } from "~/uses/useMyFetch";

export const useContentfulStore = defineStore({
  id: "contentfulStore",
  state: () => {
    return {
      slider: [],
      blog: [],
      offers: [],
      video: [],
      faq: [],
      works: [],
      underFilterSlider: [],
      topCategories: [],
      categoryUnderFilter: [],
      popUpAdvertising: [],
    };
  },
  actions: {
    async fetchBlog(quantity = undefined) {
      const { data } = await useAsyncPrivateAPI("/contentful/blog", {
        params: {
          quantity,
        },
      });
      const comparisonResult = data.filter(
        (el1) => !this.blog.some((el2) => el2.sys.id === el1.sys.id),
      );

      if (comparisonResult.length) {
        comparisonResult.forEach((el) => this.blog.push(el));
      }
    },
    async fetchBlogBySlug(slug) {
      if (!this.blog.some((el) => el.fields.url.ru === slug)) {
        const { data } = await useAsyncPrivateAPI("/contentful/blog", {
          params: {
            slug,
          },
        });

        this.blog.push(data);
      }
    },
    async fetchSlider() {
      const { data } = await useAsyncPrivateAPI("/contentful/slider");
      this.slider = data;
    },
    async fetchOffers() {
      const { data } = await useAsyncPrivateAPI("/contentful/offers");
      this.offers = data;
    },
    async fetchVideo() {
      const { data } = await useAsyncPrivateAPI("/contentful/video");
      this.video = data;
    },

    async fetchFaq() {
      const { data } = await useAsyncPrivateAPI("/contentful/faq");
      this.faq = data;
    },

    async fetchWorks() {
      const { data } = await useAsyncPrivateAPI("/contentful/works");
      this.works = data;
    },

    async fetchUnderFilterSlider() {
      const { data } = await useAsyncPrivateAPI(
        "/contentful/underFilterSlider",
      );
      this.underFilterSlider = data;
    },

    async fetchTopCategories() {
      const { data } = await useAsyncPrivateAPI("/contentful/topCategories");
      this.topCategories = data;
    },

    async fetchCategoryUnderFilter() {
      const { data } = await useAsyncPrivateAPI("/contentful/categoryUnderFilter");
      this.categoryUnderFilter = data;
    },

    async fetchPopUpAdvertising() {
      const { data } = await useAsyncPrivateAPI("/contentful/popUpAdvertising");
      this.popUpAdvertising = data;
    },
  },
  getters: {
    getSlider: (state) => state.slider,
    getSliderBySlug: (state) => {
      return (slug) => {
        return state.slider.find((item) => item.fields.url.ru === slug);
      };
    },

    getBlog: (state) => state.blog,
    getBlogBySlug: (state) => {
      return (slug) => {
        return state.blog.find((el) => el.fields.url.ru === slug);
      };
    },
    getLimitedBlog: (state) => {
      return (limit) => {
        return state.blog.slice(0, limit);
      };
    },

    getOffers: (state) => state.offers,
    getOfferBySlug: (state) => {
      return (slug) => {
        return state.offers.find((item) => item.fields.url.ru === slug);
      };
    },

    getVideo: (state) => state.video,
    getFaq: (state) => state.faq,
    getWorks: (state) => state.works,
    getUnderFilterSlider: (state) => state.underFilterSlider,
    getTopCategories: (state) => state.topCategories,
    getCategoryUnderFilter: (state) => state.categoryUnderFilter,
    getPopUpAdvertising: (state) => state.popUpAdvertising,
  },
});