<template>
  <div class="input__w">
    <div class="input">
      <input
        ref="input"
        v-model.trim="getValue"
        class="input__field"
        :type="isPasswordHide ? 'password' : 'text'"
        :autocomplete="autocompleteType"
        :class="[
          { filled: input.value },
          { error: (validate.$error && validate.$dirty) || customError },
        ]"
        @blur="onBlur"
      />
      <span class="input__caption">{{ caption }}</span>
      <div class="input__show-password" @click="toggleShowPassword">
        <img
          v-show="isPasswordHide"
          src="~~/assets/icons/eye-off.svg"
          alt="show password"
        />
        <img
          v-show="!isPasswordHide"
          src="~~/assets/icons/eye-on.svg"
          alt="hide password"
        />
      </div>
      <div
        v-show="(validate.$error && validate.$dirty) || customError"
        class="input__error-img"
      >
        <img src="~~/assets/icons/error-input.svg" alt="error attention" />
      </div>
    </div>
    <div class="input__error-caption-w">
      <p v-if="null !== customError" class="input__error-caption">
        {{ customError }}
      </p>
      <p
        v-else-if="validate.$error && validate.$dirty"
        class="input__error-caption"
      >
        {{ validate.$errors[0].$message }}
      </p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  caption: { type: String, required: false },
  modelValue: { type: String, required: false },
  validate: { type: Object, required: true },
  customError: { required: false, default: null },
  autocompleteType: { type: String, required: false },
});

const emits = defineEmits(["update:modelValue"]);

const input = ref("");
const isPasswordHide = ref(true);

const getValue = computed({
  get: () => props.modelValue,
  set: (value) => emits("update:modelValue", value),
});

function onBlur() {
  props.validate.$touch();
}

function toggleShowPassword() {
  isPasswordHide.value = !isPasswordHide.value;
}
</script>

<style lang="scss" scoped>
.input {
  position: relative;

  width: 100%;

  @include flex-container(row, space-between, center);
  gap: 4px;

  background-color: white;

  border: 1px solid #e3e5e6;
  border-radius: 8px;

  padding: 8px;

  transition: 0.2s ease;

  &:hover {
    border-color: #e3e5e6;
  }

  &:focus-within {
    border-color: #e3e5e6;
  }

  &:has(.input__field.error) {
    border-color: #fb2424;
  }

  &__w {
    max-width: 363px;
    width: 100%;

    position: relative;

    @include flex-container(column, center, flex-start);
    
    gap: 4px;
  }

  &__field {
    width: 100%;

    @include font(16, 24);

    &:focus {
      & + .input__caption {
        @include font(12, 12);

        top: 0;
        left: 12px;

        background-color: white;

        padding: 0 4px;
      }
    }

    &.filled {
      & + .input__caption {
        @include font(12, 12);

        top: 0;
        left: 12px;

        background-color: white;

        padding-inline: 4px;
      }
    }

    &.error {
      & + .input__caption {
        color: #fb2424;
      }
    }
  }

  &__show-password {
    cursor: pointer;

    & img {
      @include fixedHW(24px);
    }
  }

  &__caption {
    @include font;
    color: #7a7e80;

    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);

    pointer-events: none;

    transition: 0.2s ease;

    &:after {
      content: "*";
      color: #f73b2f;
      margin-left: 2px;
    }
  }

  &__error-img {
    & img {
      @include fixedHW(24px);
    }
  }

  &__error-caption-w {
    position: absolute;
    left: 0;
    bottom: -16px;

    @include font(12, 16);

    color: #fb2424;

    padding-inline: 8px;
  }

  &__error-caption {
  }
}
</style>
