import { Product } from "~/models/product.model";

export default definePayloadPlugin(() => {
  // Identifies the Product class
  // And then gets the data (server-side)
  definePayloadReducer("Product", (data) => {
    if (data instanceof Product) {
      return {
        dto: data,
        id: data.id,
        code: data.code,
        sku: data.sku,
        slug: data.slug,
        name: data.name,
        description: data.description,
        contents: data.contents,
        status: data.status,
        labels: data.labels,
        reviews: data.reviews,
        isOrderable: data.isOrderable,
        isDeleted: data.isDeleted,
        prices: data.prices,
        manufacturer: data.manufacturer,
        specifications: data.specifications,
        categories: data.categories,
        images: data.images,
        attachments: data.attachments,
      };
    }
  });

  // "Revive" the payload
  // Take the data -> create a new class instance
  definePayloadReviver("Product", (data) => {
    return new Product(data);
  });
});
