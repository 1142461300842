import { useAsyncAPI } from "~/uses/useMyFetch";

export const useMaintenanceStore = defineStore("maintenanceStore", {
  state: () => {
    return {
      maintenance: null,
    };
  },
  actions: {
    async fetch() {
      const { data } = await useAsyncAPI("/shared/maintenanceMode");

      this.maintenance = data;
    },
  },
  getters: {
    getIsEnabled: (state) => state.maintenance.isEnabled,
    getMessage: (state) => state.maintenance.message,
  },
});
