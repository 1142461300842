import { Category } from "~/models/category.model";
import { promotionCategoryCode } from "~/config/category.config";
import { useAPI, useAsyncAPI } from "~/uses/useMyFetch";

export const useCategoriesStore = defineStore("categoryStore", {
  state: () => {
    return {
      categories: [],
      contents: {},
      hash: null,
    };
  },
  actions: {
    async fetch() {
      const { data: categoriesDto, hash } = await useAsyncAPI(
        "/catalog/category/list",
        {
          withHash: true,
        },
      );

      this.hash = hash;

      const traverseCategoriesDto = (categoryDto, parentCategory = null) => {
        const category = new Category(categoryDto, parentCategory);

        if (category.productsCount > 0) {
          this.categories.push(category);

          categoryDto.children.forEach((childCategoryDto) =>
            traverseCategoriesDto(childCategoryDto, category),
          );
        }
      };

      categoriesDto.forEach((categoryDto) =>
        traverseCategoriesDto(categoryDto),
      );
    },
    async fetchContent(categoryId) {
      if (this.contents.hasOwnProperty(categoryId)) {
        return;
      }

      await useAPI("/catalog/category/contents", {
        params: {
          categoryId,
        },
      }).then((data) => {
        this.contents[categoryId] = data;
      });
    },
  },
  getters: {
    getCategories: (state) => {
      return state.categories;
    },
    getHash: (state) => {
      return state.hash;
    },
    getCategoryBySlug: (state) => {
      return (slug) => {
        return state.getCategories.find((category) => category.slug === slug);
      };
    },
    getCategoryById: (state) => {
      return (id) => {
        return state.getCategories.find((category) => category.id === id);
      };
    },
    getCategoryByCode: (state) => {
      return (code) => {
        return state.getCategories.find((category) => category.code === code);
      };
    },
    getChildren: (state) => {
      return (parent) => {
        return state.getCategories.filter((category) => {
          if (category.parent?.code !== promotionCategoryCode) {
            return (
              category.parent?.id === parent?.id &&
              null === category.compilation.self
            );
          }
          return (
            category.parent?.id === parent?.id &&
            null === category.compilation.self &&
            null !== category.promotion
          );
        });
      };
    },
    getCategoriesByParent: (state) => {
      return (parent) => {
        const parentCategory = state.getCategoryById(parent?.id);

        if (!parentCategory) {
          return state.getChildren(parent);
        }

        return state.getChildren(parentCategory.parent);
      };
    },
    getTopLevelCategories: (state) => state.getCategoriesByParent(null),
    getTopParentCategory: (state) => {
      return (category) => {
        if (null === category.parent) {
          return category;
        }

        const parentCategory = state.getCategoryById(category.parent.id);

        return state.getTopParentCategory(parentCategory);
      };
    },
    getCompilationCategories: (state) => {
      return (categorySlug) => {
        const currentCategory = state.getCategoryBySlug(categorySlug);
        const filteredCategories = (parentId) => {
          return state.getCategories.filter(
            (category) =>
              category.parent?.id === parentId &&
              null !== category.compilation.self,
          );
        };

        // поиск соседних категорий с текущей по родителю
        if (null !== currentCategory.compilation.self) {
          return {
            name: currentCategory.parent.compilation.children,
            categories: filteredCategories(currentCategory.parent?.id),
          };
          // поиск категорий если текущая не является подборкой
        } else if (null !== currentCategory.compilation.children) {
          return {
            name: currentCategory.compilation.children,
            categories: filteredCategories(currentCategory.id),
          };
        }

        return null;
      };
    },
    getCategoryContent: (state) => {
      return (category, contentType) => {
        if (!state.contents.hasOwnProperty(category.id)) {
          return;
        }

        const content = state.contents[category.id].find(
          ({ type }) => type === contentType,
        );

        if (undefined === content) {
          return;
        }

        return getLocalizedName(content.body);
      };
    },
  },
});
