import type { RouterConfig } from "@nuxt/schema";

// https://github.com/nuxt/nuxt/issues/26444
export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { el: to.hash };
    }

    return {
      top: 0,
    };
  },
};
