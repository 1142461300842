<template>
  <div class="offer-modal" @keydown.esc="closeModal">
    <div class="offer-modal__wrp">
      <button class="offer-modal__close" @click.stop.prevent="closeModal">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.5 4.5L4.5 13.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.5 4.5L13.5 13.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <Swiper
        @autoplayTimeLeft="onAutoplayTimeLeft"
        :slides-per-view="1"
        :loop="true"
        :autoplay="{
          delay: AUTOPLAY_TIME,
          disableOnInteraction: false
        }"
        :simulate-touch="false"
        :modules="modules"
        class="offer-modal__slider"
      >
        <SwiperSlide
          v-for="item in dataCards"
          :key="item?.id"
        >
          <OfferCard 
            :item-data="item" 
            :lang="language"
          />
        </SwiperSlide>
        <div 
          class="offer-modal__progress-bar" 
          :style="[
            { '--width': progressBar + '%' },
          ]"
        ></div>
      </Swiper>
    </div>
  </div>
</template>

<script setup>
import OfferCard from "~/modules/shared/components/infoCard/OfferCard.vue"
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";

const emits = defineEmits(["close"]);

const props = defineProps({
  dataCards: { type: Array, required: true },
  language: { type: Object, required: true }
});

const modules = [Autoplay];

const AUTOPLAY_TIME = 10000;

const progressBar = ref(0);

function closeModal() {
  emits("close");
  document.documentElement.style.overflow = "auto";
}

function onAutoplayTimeLeft(swiper, timeLeft) {
  progressBar.value = Math.round(((AUTOPLAY_TIME - timeLeft) / AUTOPLAY_TIME) * 100);
}
</script>

<style lang="scss">
.offer-modal {
  max-width: 400px;
  width: 100%;  
  height: 300px;

  position: fixed;
  left: 80px;
  bottom: 16px;
  z-index: 509;

  @include bigMobile {
    max-width: 260px;
    height: 196px;

    left: 16px;
    bottom: 80px;
  }

  &__wrp {
    height: 100%;
  }

  &__close { 
    @include fixedHW(34px, 34px);

    position: absolute;
    top: -17px;
    right: -17px;
    z-index: 510;

    @include flex-container(column, center, center);

    background-color: var(--color-ink-base);

    border-radius: 50%;
  }

  &__progress-bar {
    --width: 0;
    
    width: var(--width);
    height: 4px;

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;

    background: var(--color-primary-yellow);

    transition: width 0.1s linear;

    @include bigMobile {
      height: 2px;
    }
  }

  &__slider {
    height: 100%;

    border-radius: 16px;

    .swiper-scrollbar {
      top: auto;
      bottom: 0;

      .swiper-scrollbar-drag {
        background: var(--color-primary-yellow);
      }
    }
  }
}
</style>
