// Обвертка для изображений полученных с контентфула
export const useNormalizeCtfUrl = (url) => {
  if (undefined === url || 0 === url.length) {
    return "/img/photo-plug.svg";
  }

  const config = useRuntimeConfig();

  if (config.public.appUrl === "http://localhost:3000") {
    return "";
  }

  const urlToBtoa = btoa("https:" + url);

  return config.public.appUrl + "/static/assets/" + urlToBtoa;
};
