export class Specification {
  constructor(dto) {
    this.id = dto.id;
    this.name = dto.name;
    this.option = dto.option;
  }

  getName() {
    return getLocalizedName(this.name);
  }

  getValue() {
    return getLocalizedName(this.option.value);
  }
}
