export default [
  {
    alt: "instagram",
    link: "https://www.instagram.com/udu_ua/",
    iconBlack: "/img/instagram-black.svg",
    iconWhite: "/img/instagram-white.svg",
    iconViolet: "/img/instagram-violet.svg", 
  },
  {
    alt: "facebook",
    link: "https://www.facebook.com/profile.php?id=61563805846812",
    iconBlack: "/img/facebook-black.svg",
    iconWhite: "/img/facebook-white.svg",
    iconViolet: "/img/facebook-violet.svg",
  },
];
