export const subscriptionModal = "subscriptionModal";
export const accountModal = "accountModal";
export const preOrderModal = "preOrderModal";
export const successfulModal = "successfulModal";
export const unsuccessfulModal = "unsuccessfulModal";
export const setNewPasswordModal = "setNewPasswordModal";
export const autonomicSubscriptionModal = "autonomicSubscriptionModal";
export const cartModal = "cartModal";
export const reviewModal = "reviewModal";
export const landingPageModal = "landingPageModal";
export const offerModal = "offerModal";

export const useModalStore = defineStore("modalStore", {
  state: () => {
    return {
      modals: {},
    };
  },
  actions: {
    toggleModal(modal, extra = null) {
      if ((!modal) in this.modals[modal]) {
        this.pushModal(modal);
      }

      this.modals[modal].isActive = !this.modals[modal].isActive;
      this.modals[modal].extra = extra;
    },
    pushModal(modal) {
      this.modals[modal] = {
        isActive: false,
        extra: null,
      };
    },
  },
  getters: {
    isActiveModal: (state) => {
      return (modal) => {
        if (!state.modals.hasOwnProperty(modal)) {
          state.pushModal(modal);
        }

        return state.modals[modal].isActive;
      };
    },
    getExtra: (state) => {
      return (modal) => {
        if (!state.modals.hasOwnProperty(modal)) {
          state.pushModal(modal);
        }

        return state.modals[modal].extra;
      };
    },
  },
});
