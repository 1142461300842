<template>
  <NuxtLayout>
    <div class="no-found">
      <div class="no-found__wrp">
        <div class="no-found__content">
          <div
            class="no-found__img-wrp"
            @click="clearError({ redirect: getCurrentLang().path })"
          >
            <img :src="`/img/404-${getCurrentLang().lang}.png`" alt="cat" />
          </div>
          <h3 class="no-found__title">
            {{ _T("@Page not found") }}
          </h3>
          <p class="no-found__social-title">
            {{ _T("@Our social networks") }}
          </p>

          <div class="no-found__social-links">
            <a
              v-for="social in socials"
              :href="social.link"
              target="_blank"
              class="no-found__social-link"
            >
              <img :src="social.iconBlack" :alt="social.alt" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup>
import socials from "~/config/social.config";
import { useLanguageFilterStore } from "~~/store/languageFilterStore";

const { path } = useRoute();

const languageStore = useLanguageFilterStore();

const getCurrentLang = () => {
  if (path.startsWith("/ua")) {
    return {
      lang: "ua",
      path: "/",
    };
  }

  return {
    lang: "ru",
    path: "/ru",
  };
};

languageStore.setLanguage(getCurrentLang().lang);
</script>

<style lang="scss" scoped>
.no-found {
  height: 100%;

  @include bigMobile {
    padding: 0;
  }

  &__wrp {
    @extend %width-main;

    background-color: white;

    margin: 0 auto;
    padding: 32px 16px 48px;
  }

  &__content {
    @include flex-container(column, center, center);

    gap: 24px;
  }

  &__img-wrp {
    min-height: 290px;

    cursor: pointer;

    @include mobile {
      min-height: auto;
    }
  }

  &__title {
    @include font(24, 34, 700);
    color: #2b2b2b;
    letter-spacing: 0.02em;

    @include bigMobile {
      @include font(20, 30, 600);
    }
  }

  &__social-title {
    @include font(18, 24, 500);
    letter-spacing: 0.02em;
    color: var(--color-primary-base);
  }

  &__social-links {
    @include flex-container(row, space-around, center);

    gap: 24px;
  }

  &__social-link {
  }
}
</style>
