import { trackEvent } from "~/utils/trackEvent";
import { useAPI } from "~/uses/useMyFetch";
import { Product } from "~/models/product.model";

export const useCartStore = defineStore("cartStore", {
  state: () => {
    return {
      cart: [],
    };
  },
  actions: {
    async fetch() {
      await useAPI("/catalog/product/list/cart").then((data) => {
        this.cart = data.map((dto) => {
          return {
            ...dto,
            product: new Product(dto.product),
          };
        });
      });
    },
    async addItem(item) {
      const product = new Product({ ...item });
      const existedItem = this.cart.find((el) => el.product.id === item.id);

      if (!existedItem) {
        this.cart.unshift({ product, quantity: 1, createdAt: "" });

        await useAPI("/catalog/product/cart/add", {
          method: "POST",
          body: {
            productId: product.id,
            quantity: 1,
          },
        }).then(() => {
          const event = {
            event: "add_to_cart",
            action: "click",
            label: "add to cart",
            noninteraction: "false",
            ecommerce: {
              items: [
                {
                  item_name: product.slug,
                  item_id: product.code,
                  price: product.getPrice(Product.PRICE_TYPE.CURRENT),
                  item_brand: product.manufacturer.name,
                  item_category: product.getMainCategory(),
                  quantity: 1,
                },
              ],
            },
          };
          trackEvent(event);
        });

        await this.fetch();
      }
    },
    async removeItem(item) {
      const product = this.cart.find((el) => el === item);
      this.cart = this.cart.filter((el) => el.product.id !== item.product.id);
      await useAPI("/catalog/product/cart/remove", {
        method: "POST",
        body: {
          productId: item.product.id,
          quantity: product.quantity,
        },
      });

      await this.fetch();
    },
    /// ////////Set quantity of product///////////
    async setQuantity(item, qty) {
      if (qty < 0) {
        await useAPI("/catalog/product/cart/remove", {
          method: "POST",
          body: {
            productId: item.product.id,
            quantity: -1 * qty,
          },
        });
      } else {
        await useAPI("/catalog/product/cart/add", {
          method: "POST",
          body: {
            productId: item.product.id,
            quantity: qty,
          },
        });
      }

      await this.fetch();
    },
  },
  getters: {
    getCart: (state) => state.cart,
    getTotalQuantity: (state) => {
      return state.cart.reduce((acc, el) => acc + el.quantity, 0);
    },
    getProductsQuantity: (state) => state.cart.length,
    getTotalSaleAmount: (state) => {
      return state.cart.reduce((amount, item) => {
        if (undefined === item.totalSalePrice) {
          return amount;
        }
        return amount + item.totalSalePrice.amount;
      }, 0);
    },
    getTotalAmount: (state) => {
      return state.cart.reduce((amount, item) => {
        if (undefined === item.totalPrice) {
          return amount;
        }
        return amount + item.totalPrice.amount;
      }, 0);
    },
    hasProduct: (state) => {
      return (product) => {
        return state.cart.some(
          ({ product: cartProduct }) => cartProduct.id === product.id,
        );
      };
    },
    isEmpty: (state) => {
      return 0 === state.cart.length;
    },
  },
});
